<template>
  <overlay
    :show="loading"
  >
    <div class="content-wrapper">
      <page-header
        :screen-name="pageTitle"
        :link-items="linkItems"
      />
      <div class="card p-2">
        <b-row>
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Nome completo*</h5>
              <p>{{ chooseCompanyUser.nome }}</p>
            </div>

            <div class="mb-4">
              <h5>CPF*</h5>

              <p>{{ chooseCompanyUser.cpf | VMask('###.###.###-##') }}</p>
            </div>

            <div class="mb-4">
              <h5>Perfil*</h5>
              <p
                v-for="(profile, index) in chooseCompanyUser.perfil"
                :key="index"
              >{{ profile.descricao }}</p>
            </div>
          </b-col>

          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>E-mail*</h5>
              <p>{{ chooseCompanyUser.email }}</p>
            </div>

            <div class="mb-4">
              <h5>Sexo*</h5>
              <p>{{ chooseCompanyUser.sexo }}</p>
            </div>

            <div class="mb-4">
              <h5>Empresas*</h5>
              <span
                v-for="(company, index) in chooseCompanyUser.empresa"
                :key="index"
              >

                {{ company.nome_empresa }}
                <br>
              </span>
            </div>
          </b-col>

          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Data de nascimento*</h5>
              <p>{{ chooseCompanyUser.data_nascimento }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <button
              type="button"
              class="btn custom-blue"
              @click="redirectUpdatePage"
            >
              <feather-icon
                icon="EditIcon"
              />
              Editar
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
  </overlay>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { getCompanyUserId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import { warningMessage } from '@/libs/sweetalerts'

export default {
  components: {
    BRow,
    BCol,
    Overlay,
    PageHeader,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          active: true,
          routeName: 'usuario-empresa-list',
        },
        {
          name: '...',
          active: true,
        },
      ],

      moment,

      pageTitle: '...',

      loading: true,

      chooseCompanyUser: {
        id_usuario: '-',
        nome: '-',
        email: '-',
        cpf: '-',
        ativo: '-',
        data_nascimento: '-',
        sexo: '-',
        perfil: [],
        empresa: [],
      },
    }
  },

  computed: {
    getItemInStore() {
      return this.$store.getters['companyUserState/getCompanyUserView']
    },
  },

  created() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()
    }

    this.handleGetCompanyUserId()
  },

  methods: {
    async handleGetCompanyUserId() {
      this.loading = true

      await getCompanyUserId(this.getItemInStore.id_usuario)
        .then(response => {
          const {
            id_usuario,
            nome,
            email,
            cpf,
            ativo,
            data_nascimento,
            sexo,
            empresa,
            perfil,
          } = response.data

          this.chooseCompanyUser = {
            id_usuario,
            nome,
            email,
            cpf,
            ativo,
            data_nascimento: moment(data_nascimento).format('DD/MM/YYYY'),
            sexo,
            empresa,
            perfil,
          }

          this.pageTitle = nome
          this.linkItems[1].name = nome
        })
        .catch(() => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.loading = false
    },

    redirectToMainPage() {
      this.$store.commit('companyUserState/setCompanyUserView', null)
      this.$router.replace({ name: 'usuario-empresa-list' })

      return false
    },

    redirectUpdatePage() {
      this.$store.commit('companyUserState/setCompanyUserUpdate', this.chooseCompanyUser)

      this.$router.replace({ name: 'usuario-empresa-edit' })
    },
  },

}
</script>
